import React, {cloneElement, ReactElement, useState} from 'react'
import {Container, Grid} from "@mui/material";
import SEO from "../../components/seo/seo";
import Text from "../../components/data-display/text";
import InlineLink from "../../components/data-display/inline-link";
import SydeTaskerTerms from "./SydeTaskerTerms";
import QuickTaskTerms from "./QuickTaskTerms";
import { isQuickTaskProject, title } from '../../utils/constant.urls';
  
  


const TermsOfService = () => {
  return (
    <>
      <SEO
        description={`${title} will solely share and disclose your data in accordance with your instructions, including any applicable terms in the Terms of Service, and in compliance with applicable law and legal process.`}
        title={"Terms of Service"}
      />
      <div
        style={{
          width: "100%",
          background: "rgb(235,235,235)",
          height: "30vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{height: "64px"}} />
        <Text variant={"h1"} black style={{textAlign: "center"}}>
          {"Terms of Service"}
        </Text>
      </div>
      <Container fixed maxWidth={"md"}>
        {isQuickTaskProject ? <QuickTaskTerms /> : <SydeTaskerTerms />}
      </Container>
      <div style={{height: "1px", width: "100%", background: "rgb(220,220,220)"}} />
    </>
  );
};

export default TermsOfService